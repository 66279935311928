import React from "react"
import { useSelector } from "react-redux"
const MainSection = () => {
  const { language } = useSelector(state => state.global)
  const { banner } = useSelector(state => state.about)

  return (
    <div className="banner about_banner">
      <div className="container">
        {/* <span className="meyil">{preTitle}</span> */}
        <div className="banner_txt">
          <div>
            <div className="light img-fluid">
              <img src={banner.img.sourceUrl} alt={banner.img.title} />
            </div>
            <h1 className="split-text">{banner.bannerTitle[language]}</h1>
            <p className="split-text">
              <span className="banner_txtclr">
                {banner.highlightedText[language]}
              </span>
              {banner.text[language]}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainSection
