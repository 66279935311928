import React from "react"
import CountUp from "react-countup"
import Lottie from "react-lottie"
import years from "../../images/json_file/years.json"
import happyClients from "../../images/json_file/Happy_Clients.json"
import happyEmploy from "../../images/json_file/Happy_Employ.json"
import revenueGenerated from "../../images/json_file/revenue_generated.json"
import { useSelector } from "react-redux"

const OurSpecialities = () => {

  const icons = [years, happyClients, happyEmploy, revenueGenerated]

  const { language } = useSelector(state => state.global)
  const { ourStory, storyRepeater } = useSelector(state => state.about)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <section className="ask_webdura about_webdure">
      <div className="container">
        <div className="row text_side">
          <div className="txt_block">
            <div className="lftbrdr">
              <h6 style={{ color: "#fff" }}>{ourStory.title[language]}</h6>
              <h1 className="split-text">{ourStory.text[language]}</h1>
            </div>
          </div>
          <div className="para_block">
            <p>{ourStory.paragraph[language]}</p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="whiteset">
          <p>{ourStory.paragraph1[language]}</p>
          <div className="about_view">
            {storyRepeater && storyRepeater.map((story, index) => (
              <div className="about_number" key={index}>
                <div className="icon_player">
                  <Lottie
                    options={{ ...defaultOptions, animationData: icons[index] }}
                  />
                </div>
                <span className="stat-number">
                  <CountUp end={story.count} duration={5} />
                </span>
                <span>{story.title[language]}</span>
                <h6 className="split-text">{story.text[language]}</h6>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurSpecialities
