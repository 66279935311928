import React from "react"
import { useDispatch } from "react-redux"
import Layout from "../components/layout"
import AboutPageContent from "../components/AboutUs"
import {
  ABOUT_PAGE_QUERY_REQUEST,
  ABOUT_PAGE_QUERY_SUCCESS,
} from "../store/actionTypes"
import { graphql, StaticQuery } from "gatsby"
import Seo from "../components/seo"

const AboutUs = () => {
  const dispatch = useDispatch()
  const query = graphql`
    {
      wdwordpress {
        page(id: "cGFnZTo3Ng==") {
          aboutpage_contents {
            banner {
              img {
                sourceUrl
                title
              }
              bannerTitle {
                en
                sv
              }
              highlightedText {
                en
                sv
              }
              text {
                en
                sv
              }
            }
            brandBoxRepeater {
              hoverText {
                en
                sv
              }
              primaText {
                en
                sv
              }
            }
            brandSet {
              text {
                en
                sv
              }
              title {
                en
                sv
              }
            }
            leaders {
              para {
                en
                sv
              }
              text {
                en
                sv
              }
              title {
                en
                sv
              }
              list {
                name
                img {
                  uri
                  sourceUrl
                }
                position {
                  en
                  sv
                }
              }
              movement {
                en
                sv
              }
            }
            ourStory {
              title {
                en
                sv
              }
              text {
                en
                sv
              }
              paragraph1 {
                en
                sv
              }
              paragraph {
                en
                sv
              }
            }
            storyRepeater {
              text {
                en
                sv
              }
              title {
                en
                sv
              }
              count
            }
            teamDivision {
              title1 {
                en
                sv
              }
              title2 {
                en
                sv
              }
              title3 {
                en
                sv
              }
            }
          }
        }
      }
    }
  `
  return (
    <StaticQuery
      query={query}
      render={({ wdwordpress }) => {
        dispatch({ type: ABOUT_PAGE_QUERY_REQUEST })
        dispatch({ type: ABOUT_PAGE_QUERY_SUCCESS, payload: wdwordpress })
        return (
          <>
            <Layout>
              <Seo title="About Us" pageId />
              <AboutPageContent />
            </Layout>
          </>
        )
      }}
    />
  )
}

export default AboutUs
