import React from "react"
import { useSelector } from "react-redux"

import TeamCover from "../../images/team.jpg"
import TeamLap from "../../images/team_lap.jpg"
import HappyTeam from "../../images/happy_team.jpg"
import TeamMeeting from "../../images/team_meeting.jpg"
import TeamEffect from "../../images/team_effect.jpg"

const OurApproach = () => {
  const { language } = useSelector(state => state.global)
  const { teamDivision } = useSelector(state => state.about)
  return (
    <section>
      <div className="team_division">
        <div className="team_big">
          <img alt="img" src={TeamCover} />
        </div>
        <div className="team_small bgclr_small small_mob">
          <p>{teamDivision.title1[language]}</p>
        </div>
        <div className="team_small d-none d-sm-none d-md-block">
          <img alt="img" src={TeamLap} />
        </div>
        <div className="team_small bgclr_small small_mob">
          <p>{teamDivision.title2[language]}</p>
        </div>
        <div className="team_small">
          <img alt="img" src={HappyTeam} />
        </div>
        <div className="team_small bgclr_small">
          <img alt="img" src={TeamMeeting} />
          <p>{teamDivision.title3[language]}</p>
        </div>
        <div className="team_small d-none d-sm-none d-md-block">
          <img alt="img" src={TeamEffect} />
        </div>
      </div>
    </section>
  )
}

export default OurApproach
