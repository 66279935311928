import React from "react"
import { useSelector } from "react-redux"
import Brand1 from "../../images/brand1.jpg"
import Brand2 from "../../images/brand2.jpg"
import Brand3 from "../../images/brand3.jpg"

const Experience = () => {

  const imgs = [Brand1, Brand2, Brand2, Brand3]

  const { language } = useSelector(state => state.global)
  const { brandSet, brandBoxRepeater } = useSelector(state => state.about)

  return (
    <section className="brandset">
      <div className="black_bg">
        <div className="container">
          <h2 className="split-text">{brandSet.title[language]}</h2>
          <p>{brandSet.text[language]}</p>
        </div>
      </div>
      <div className="brand_boximg">
        {brandBoxRepeater.map((brand, index) => (
          <div className="brand_box" key={index}>
            <img alt="img" src={imgs[index]} />
            <div className="top_txt">
              <h3>{brand.primaText[language]}</h3>
              {brand.hoverText[language] ? <p>{brand.hoverText[language]}</p> : null}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Experience
