import React from "react"
import BannerSection from "./MainSection"
import OurSpecialities from "./OurSpecialities"
import Experience from "./Experience"
import OurTeam from "./OurTeam"
import KnowledgeSharing from "./OurApproach"
import Explore from "../Common/Explore"
// import ChatBox from "../Common/ChatBox"

export default () => (
  <>
    <BannerSection />
    <OurSpecialities />
    <Experience />
    <OurTeam />
    <KnowledgeSharing />
    <Explore />
    {/* <ChatBox /> */}
  </>
)
